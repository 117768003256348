/// <reference path="../../services/workflows.services.ts" />
/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/scheduleJob.services.ts" />
/// <reference path="../../services/workflows.services.ts" />

//this is base class extended by action schedule controller which used in schedule list page 
class baseSchedule {
    order: orderby;
    page: number = 1;
    pagesize: number = globalPageSize;
    pageCount: number = 0;
    totalItems: number = 0;
    // currentPage : number = 1;
    loading: boolean = false;
    scrollloading: boolean = true;
    searchstring: string = '';
    showsubflows: boolean;
    entities: Array<any> = [];
    inlineCount: number;
    deletecounter: number = 0;
    service: socketServiceClass;
    namespace: ScheduleJobsServiceClass;
    workflowsService: WorkflowsServiceClass;
    genericServices: GenericServiceClass;
    scheduleServices: ScheduleJobsServiceClass;
    focuson: string = 'searchstring';
    public collection: string = '';
    public basequery: any = null;
    public baseprojection: any = null;

    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory
    ) {
        this.order = new orderby({ field: "_created", reverse: true })
        this.service = socketService.namespace;
        this.namespace = new ScheduleJobsServiceClass(this.service.socket, this.service.namespace);
        this.workflowsService = new WorkflowsServiceClass(this.service.socket, this.service.namespace);
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.scheduleServices = new ScheduleJobsServiceClass(this.service.socket, this.service.namespace)
    }
    orderby(field: string) {
        if (field == this.order.field) {
            this.order.reverse = !this.order.reverse;
        }
        else {
            this.order.field = field;
            this.order.reverse = false;
        }
        this.entities = [];
        this.page = 1;
        //this.loaddata();
        this.getShedulesPage()
    }
    async loaddata() {
        clearError(this.$scope);
        if (this.collection == 'workflowschedules') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'users') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'files') {
            this.service = this.socketService.namespace;
        }
        if (!this.genericServices.isConnected) return;

        this.loading = true;
        // var t0 = performance.now();
        var query = this.basequery;
        if (this.searchstring !== '') {
            query.name = { $regex: '/^' + this.searchstring + '$/i' };
        }
        try {
            var items = await this.genericServices.query(query, this.baseprojection, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection);
            this.loading = false;
            this.entities = this.entities.concat(items);
            this.scrollloading = (items.length > 0);
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    async getShedulesPage() {
        clearError(this.$scope);
        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        } else if (this.collection == 'users') {
            this.service = this.socketService.namespace;
        }
        //debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
        // var t0 = performance.now();
        var query = this.basequery;
        if (this.searchstring == null || this.searchstring == '') {
            if (query && query.name) {
                delete query.name;
            }
        }

        if (this.searchstring.trim() !== '') {
            if (!query) { query = {} }
            query.name = { $regex: ".*" + this.searchstring + ".*", $options: "i" }
        }

        try {
            var user: any = this.socketService.user;

            // if user not admin then get owned schedules only  
            if (!user.roles.find(R => R._id == adminsRoleId)) {
                query = { $or: [{ _createdbyid: user._id }, { userMachines: { $elemMatch: { id: user._id } } },] }
            }

            let projection = {'workflows._id':1,'workflows.name':1 , 'name': 1 , 'translatedCornEx':1, 'cronEx':1, '_created':1,'status':1}
            var Result: any = await this.scheduleServices.getShedulePageService(query, projection, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection, true);
            var items = Result.items;
            for (var item in items){
                var cronExpV = items[item].cronEx
                if (!cronExpV){
                    return;
                 }
                 await this.genericServices.nextRunCron(cronExpV).then((resolve) => {
                    items[item].nextCron = resolve
                 }
                 ); 
              }
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);
            if (this.pageCount < this.page) {
                this.page = this.pageCount;
            }
            this.loading = false;
            this.entities = items;
            this.scrollloading = (items.length > 0);
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }

    GetShedulesData(pageNumber: number) {
        this.page = pageNumber;
        this.getShedulesPage();
    }

    async delete(id) {
        if (!this.deletecounter) {
            this.deletecounter = 0;
        }
        this.loading = true;
        try {

            await this.namespace.DeleteScheduleJob(id);
            this.entities.forEach((entity, idx) => {
                if (entity._id == id) {
                    this.entities.splice(idx, 1);
                }
            })
            this.deletecounter++;
            this.loading = false;
            //if (this.deletecounter > 5 || this.entities.length == 0) {
            if (this.entities.length == 0) {
                this.page = 1;
                this.entities = [];
                this.deletecounter = 0;
                this.getShedulesPage();
            } else {
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }

        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    prettyDate(time) {
        try {
            var date = new Date(Date.parse(time));
            var diff = (((new Date()).getTime() - date.getTime()) / 1000);
            var day_diff = Math.floor(diff / 86400);
            if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) {
                return this.toFormatedDate(time)
            }
            return day_diff == 0 && (diff < 60 && Math.floor(diff) + " sec ago" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff == 1 && "Yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago";
        }
        catch (err) {
            return time;
        }
    }
    async translate(conrn) {
        await this.genericServices.translateCron(conrn).then((resolve) => {
            return resolve;
        }
        );

    }
    toLocaleDateString(time) {
        return new Date(time).toLocaleDateString();
    }

    toFormatedDate(time) {
        var myDate = new Date(time);
        return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear();

    }

    search() {
        this.entities = [];
        this.page = 1;
        this.getShedulesPage();
        //this.loaddata();
    }
    more() {
        if (!this.genericServices.isConnected()) return;
        if (!this.scrollloading) { return; }
        if (this.loading == true) { return; }
        this.loading = true;
        this.page++;
        this.getShedulesPage();
    }
}

//this controller is used by schedule page to retrieve all scheduled jobs 
//its a extened version as the robotactions but with modifications
class actionscheduleCtrl extends baseSchedule {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', 'toastr'];
    showcompleted: boolean = false;
    deletecounter: number = 0;
    data: any;
    toastrObj: any;
    genericServices: GenericServiceClass;
    workflowServices: WorkflowsServiceClass;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;
        this.collection = 'workflowschedules';
        var user: any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.workflowServices = new WorkflowsServiceClass(this.service.socket, this.service.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == rpaDesignerRoleId) && !user.roles.find(R => R._id == rpaSchedulerRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.order = new orderby({ field: "_modified", reverse: true })
        this.socketService.onSignedin(() => {
            this.basequery = {};

          //  this.baseprojection = { xaml: 0 };


            // this.loaddata();
            this.getShedulesPage();
        });
    }
    async updateScheduleStatus(model) {
        console.log('updateScheduleStatus');
        await this.namespace.UpdateScheduleStatus(model);

    }
    async ToggleScheduleJobStatus(id) {
        this.loading = true;
        await this.namespace.ToggleScheduleJobStatus(id);

    }
    adjustcompleted() {
        this.basequery = { $or: [{ _type: 'unknown' }, { _type: 'rpaworkflow' }] };

        this.page = 1;
        this.entities = [];
        this.getShedulesPage();
    }
    async invoke(model) {
        var me = this;
        var robotid = model.robotid;
        var actionid = model._id;
        model.IsRunning = true;
        this.data = null;
        clearError(this.$scope);
        try {
            var data = await this.workflowServices.invokeRobotService(robotid, actionid, {});
            if (data.error) {
                // handleError(this.$scope, data.error);
                this.toastrObj.error(GetErrorMessage(data.error));
                model.IsRunning = false;
                return;
            }
            me.data = data;
            model.IsRunning = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            model.IsRunning = false;
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async stop(model) {

        var me = this;

        var robotid = model.robotid;
        var actionid = model._id;
        this.data = null;
        clearError(this.$scope);
        try {
            var data = await this.workflowsService.onstopworkflow(robotid, actionid, {});

            if (data.error) {
                this.toastrObj.error(GetErrorMessage(data.error));
                model.IsRunning = false;
                return;
            }
            me.data = data;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            model.IsRunning = false;
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async toggleshared(model: base) {
        var well_known_robots_id = '5ac0850ca538fee1ebdb996c';
        var well_known_admins_id = '5a1702fa245d9013697656fb';
        try {
            model = base.assign(model);
            if ((model as any).shared == true) {
                (model as any).shared = false;
                //model.removeace(well_known_robots_id);
                model._acl = [];
                model.addace(well_known_admins_id, 'admins');
                model.addace((model as any).robotid, (model as any).robotname);
                await this.genericServices.put(model, 'workflows');
            }
            else {
                (model as any).shared = true;
                model._acl = [];
                model.addace(well_known_admins_id, 'admins');
                model.addace(well_known_robots_id, 'robots');
                await this.genericServices.put(model, 'workflows');
            }
            this.page = 1;
            this.entities = [];
            this.getShedulesPage();


        } catch (err) {
        }
    }



}

// this controller is used in cron page to schedule a job and extends baseEntities to get the current workflows 
class cronCtrl extends baseEntities2Ctrl {
    pluginName: any;
    cronex: any;
    croned: any;
    nextCronRun: any = [];
    cronConfig: any;
    service: socketServiceClass;
    userservice: socketServiceClass;
    namespace: ScheduleJobsServiceClass;
    genericServices: GenericServiceClass;
    isCronDisabled: any
    DisabledEdit = false;
    name = "";
    userbasequery: any;
    flow: any;
    project: any;
    userMachine: any
    zone: any;
    flows: any = [];
    robot: any;
    userentities: Array<any> = [];
    flowNames: any = [];
    timing: any;
    typed: any;
    projectId: any;
    usercollection: any;
    cronExpression: any;
    cronConfig1: any;
    cronOptions1: any;
    MondayChecked: boolean = true;
    TuesdayChecked: boolean = false;
    WednesdayChecked: boolean = false;
    ThursdayChecked: boolean = false;
    FridayChecked: boolean = false;
    SaturdayChecked: boolean = false;
    SundayChecked: boolean = false;
    static $inject: Array<string> = ['$window', '$route', '$scope', 'socketService', '$timeout', '$routeParams', '$location', '$compile', 'toastr'];
    model: any = {};

    id: any;

    toastrObj: any;

    constructor(
        public $window,
        public $route,
        public $scope,
        public socketService: socketServiceFactory,
        public $timeout,
        public $routeParams,
        public $location,
        public $compile,
        public toastr


    ) {

        super($scope, $location, $compile, socketService);
        this.toastrObj = toastr;

        this.id = $routeParams.id


        this.cronConfig = {

            allowMultiple: true

        };

        this.userentities = []
        this.collection = 'workflows';
        var user: any = this.socketService.user;
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == rpaDesignerRoleId) && !user.roles.find(R => R._id == rpaSchedulerRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.usercollection = 'users';
        this.order = new orderby({ field: "_modified", reverse: true })
        this.service = socketService.namespace;
        this.userservice = socketService.namespace;
        this.namespace = new ScheduleJobsServiceClass(this.service.socket, this.service.namespace)
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)

        // this.namespace = socketService.namespace;
        this.model.userMachines = [];
        // intial value of the inputs 
        // this.model.everyMinutesCount = '1';
        // this.model.everyHoursCount = 1 ;
        // this.model.hourlyAtMinute = 0 ;
        // this.model.dailyAtHour = 1 ;
        // this.model.dailyAtMinute = 0 ;
        // this.model.weeklyAtHour = 1 ;
        // this.model.weeklyAtMinute = 0 ; 
        // this.model.Monday = true;
        // this.model.monthlyAtHour = 1 ;
        // this.model.monthlyAtMinute = 0 ; 
        // this.model.atDay ='1' ;
        // this.model.Days = []
        this.model.timeType = 'Minutes';
        this.model.cronEx = "* * * * *"
        //two option if there's id so it's page in edit mode load job data and workflows
        //else load only the current workflows

        this.socketService.onSignedin(async () => {
            //if in edit mode 
            if (this.id) {
                this.basequeryProjects = { _type: 'project' };
                this.userbasequery = "_type eq 'user'";
                this.baseprojection = { xaml: 0 };
                
                await this.loaddataid(this.id);
                this.loadProjectsData();
                this.loadProjectWorkflows();
                this.loadusers();
                 

            }
            //if in add mode
            else {

                this.basequeryProjects = { _type: 'project' };
                this.basequery = { $or: [{ projectid: this.projectId }, { _type: 'rpaworkflow' }] };
                this.userbasequery = "_type eq 'user'";
                this.baseprojection = { xaml: 0 };
                console.log(this.projectsEntities);
                this.loadProjectsData();
                this.loadusers()

            }


        });

        var vm = this;

        // add a watcher to all timetype to activate the next cron parser function
        var timeTypeValues = ['ctrl.model.cronEx', 'ctrl.model.everyMinutesCount', 'ctrl.model.everyHoursCount',
         'ctrl.model.hourlyAtMinute', 'ctrl.model.dailyAtHour', 'ctrl.model.dailyAtMinute',
         'ctrl.Days', 'ctrl.model.Days', 'ctrl.MondayChecked', 'ctrl.TuesdayChecked', 'ctrl.WednesdayChecked', 'ctrl.ThursdayChecked',
         'ctrl.FridayChecked', 'ctrl.SaturdayChecked', 'ctrl.SundayChecked',
         'ctrl.model.weeklyAtHour', 'ctrl.model.weeklyAtMinute',
         'ctrl.model.atDay', 'ctrl.model.monthlyAtHour', 'ctrl.model.monthlyAtMinute']

        $scope.$watchGroup(timeTypeValues, function () {
            vm.trial(true);
            vm.nextCronParser();
        });
       
        $scope.$watch('ctrl.model.timeType', function () {
            vm.cronex='';
            vm.croned='';
            vm.nextCronRun=[];
        });

    }

    async loadProjectsData() {
        clearError(this.$scope);
        this.service = this.socketService.namespace;

        if (!this.genericServices.isConnected) return;

        this.loading = true;
        // var t0 = performance.now();
        var query = this.basequery;
        var projectsQuery = this.basequeryProjects;
        if (this.searchstring !== '') {
            query.projectandname = { $regex: '/^' + this.searchstring + '$/i' };
        }


        try {

            // if user not admin then get owned items only  
            var user: any = this.socketService.user;
            if (!user.roles.find(R => R._id == adminsRoleId)) {
                if (projectsQuery) {
                    projectsQuery = { $and: [projectsQuery, { $or: [{ _createdbyid: user._id }, { _acl: { $elemMatch: { _id: user._id, "permissions.isRead": true } } }] }] };//' and _createdbyid eq ' + "'" + user._id+ "'" ;
                } else {
                    projectsQuery = { $and: [{ _type: "project" }, { _createdbyid: user._id }] };
                }

            }

            let projction = {'_id':1,'name':1}

            //projects
            var projects = await this.genericServices.getAll(projectsQuery , projction ,  this.collection);
            this.loading = false;
            this.projectsEntities = projects ; 


            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    //this function is used when user change project to load it's workflows
    async projectChanged() {
        this.entities = [];
        this.model.userMachines = [];
        this.model.workflows = []; 
        this.basequery = "_type eq 'rpaworkflow'";
        this.basequery += " and projectid eq '" + this.model.projectId + "'";
        let projection = {'_id':1,'name':1}
        var items = await this.genericServices.getWithProjection(this.basequery,projection, null, null, null, false, 'workflows');
        this.entities = this.entities.concat(items);
        this.loadusers();
    }
    //this function load job data if page in edit mode 
    async loaddataid(id) {
        this.loading = true;
        var query = "_id eq '" + id + "'";
        try {
            var items = await this.genericServices.get(query, 1, null, null, null, 'workflowschedules');
            this.loading = false;
            if (items.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such Job ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such Job ' + id } }));
                return;
            }
            this.model = items[0];
            if (this.model.timeType == "Weekly") {
                if (this.model.Days.length > 0) {
                    this.model.Days.includes("MON") ? this.MondayChecked = true : this.MondayChecked = false;
                    this.model.Days.includes("TUE") ? this.TuesdayChecked = true : this.TuesdayChecked = false;
                    this.model.Days.includes("WED") ? this.WednesdayChecked = true : this.WednesdayChecked = false;
                    this.model.Days.includes("THU") ? this.ThursdayChecked = true : this.ThursdayChecked = false;
                    this.model.Days.includes("FRI") ? this.FridayChecked = true : this.FridayChecked = false;
                    this.model.Days.includes("SAT") ? this.SaturdayChecked = true : this.SaturdayChecked = false;
                    this.model.Days.includes("SUN") ? this.SundayChecked = true : this.SundayChecked = false;

                }

            }

            this.projectId = this.model.projectId;

            await this.trial(true);
            await this.nextCronParser();
            this.DisabledEdit = false;
            if (this.model.ownerid || this.model.isOwner == true || this.model.CommunityLicense == true) {
                this.DisabledEdit = true;
            }

            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            this.focusfirstinput();
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    async loadProjectWorkflows() {
        this.entities = [];
        this.basequery = "_type eq 'rpaworkflow'";
        this.basequery += " and projectid eq '" + this.model.projectId + "'";
        await this.genericServices.get(this.basequery, null, null, null, null, 'workflows').then((response) => {


            this.entities = this.entities.concat(response);
            this.$scope.$apply();
        }
        );

        this.loadusers();

    }


    //partial function used by load data by id 
    focusfirstinput() {
        let data = this.model;
        if (!data)
            return;
        if (!data.ui)
            return;
        if (data.ui.length == 0)
            return;
        var name = null;
        for (var i = 0; i < data.ui.length; i++) {
            if (data.ui[i].readonly == false) {
                name = data.ui[i].name;
                break;
            }
        }
        if (name) {
            this.$timeout(() => {
                this.$scope.$broadcast(name, null);
            }, 50);
        }
    }
    async loadusers() {
        clearError(this.$scope);
        if (this.usercollection == 'workflows') {
            this.userservice = this.socketService.namespace;
        } else if (this.usercollection == 'users') {
            this.userservice = this.socketService.namespace;
        }


        if (!this.genericServices.isConnected()) return;

        // var user: any = this.socketService.user;

        this.loading = true;
        // var t0 = performance.now();
        var query = this.userbasequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            query += "contains(name,'" + this.searchstring + "')";
        }
        try {
            this.model.projectId ? this.projectId = this.model.projectId : this.projectId;
            let items: any;
            if (this.projectId) {

                items = await this.genericServices.get_permitted_users_on_prject(this.projectId, this.limit, null, this.order.field, this.order.reverse, this.usercollection);
                //if (odata.count) { this.inlineCount = odata.count; }
                this.userentities = [];
                items.forEach(member => {
                    this.userentities.push({ name: member.name, id: member._id })
                });

                //le.log(this.userentities);
                this.scrollloading = (items.length > 0);
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            this.loading = false;

        } catch (err) {
            this.toastrObj.error(GetErrorMessage(err));
        }



    }

    //this function calls translate cron function on the server which take the cron expression
    //and convert it into readable text 
    //first few lines of code before the function call to edit the months part of the cron expression
    //to match with the library syntax by adding 1 to month number 
    async trial(edit: boolean) {
        
        var cronExpV 
       
        this.model.timeType == 'Advanced' ? cronExpV = this.model.cronEx : cronExpV = this.FormatCronExpression(this.model);
        var index = cronExpV.indexOf("undefined"); 
        if(index!=-1){
            this.croned = '';
            return;
        }
        else if (!cronExpV  || cronExpV=='' ) {
            this.croned = '';
            return;
        }
        // if (edit) {
        //     let cronArray: any;
        //     let cronMonthArray: any;
        //     let cronMonthString: any;

        //     cronArray = cronExpV.split(" ");

        //     const indx = cronArray.length === 6 ? 4 : 3;

        //     if (cronArray[indx] != "*") {
        //         if (cronArray[indx].includes(",")) {
        //         cronMonthArray = cronArray[indx].split(",");
        //         for (var i = 0; i < cronMonthArray.length; i++) {
        //             if (!isNaN(cronMonthArray[i]))
        //             cronMonthArray[i] = (parseInt(cronMonthArray[i]) + 1).toString();
        //             else
        //              cronMonthArray[i] = cronMonthArray[i];
        //         }
        //         cronMonthString = cronMonthArray.toString();
        //         cronArray[indx] = cronMonthString;
        //         cronExpV = cronArray.join(" ");
        //         } else if (cronArray[indx].includes("-")) {
        //         cronMonthArray = cronArray[indx].split("-");
        //         for (var i = 0; i < cronMonthArray.length; i++) {
        //             if (!isNaN(cronMonthArray[i]))
        //             cronMonthArray[i] = (parseInt(cronMonthArray[i]) + 1).toString();
        //             else cronMonthArray[i] = cronMonthArray[i];
        //         }
        //         cronMonthString = cronMonthArray.toString().replace(",", "-");
        //         cronArray[indx] = cronMonthString;
        //         cronExpV = cronArray.join(" ");
        //         } else {
        //         cronMonthString = cronArray[indx];
        //         if (!isNaN(cronArray[indx])) {
        //             cronMonthString = (parseInt(cronMonthString) + 1).toString();
        //             cronArray[indx] = cronMonthString;
        //             cronExpV = cronArray.join(" ");
        //         }
        //         }
        //     }
            edit = false;
            this.model.cronEx = cronExpV;
            // }
            console.log(cronExpV)
            await this.genericServices.translateCron(cronExpV).then((resolve) => {
            this.croned = resolve;
            this.$scope.$apply();
            });
    }

    async nextCronParser() {
        var cronExpV:string;
        var cronArray
       
        this.model.timeType == 'Advanced' ? cronExpV = this.model.cronEx : cronExpV = this.FormatCronExpression(this.model);
       
        cronArray = cronExpV.split(" ");
       
        if (!cronExpV || (cronArray.length!=5 && cronArray.length!=6) ){
            this.nextCronRun = [];
            return;
        }
        await this.genericServices.nextRunCron(cronExpV).then((resolve) => {
            this.nextCronRun = resolve;
            this.model.nextCron = this.nextCronRun[0]
            this.$scope.$apply();
        }
        );
    }

    async ToggleScheduleJobStatus(id) {
        this.loading = true;
        await this.namespace.ToggleScheduleJobStatus(id);
        this.relocate();
    }
    //to save schedule after makeing modifications 
    //first delete the old job and then add new job with the edited requirments
    //then go to schedule page
    async SaveEditedSchedule() {
        this.loading = true;

        // save dayes as array of string from the check boxes 
        let weekDays: string[] = [];
        if (this.model.timeType == 'Weekly') {
            if (this.MondayChecked) { weekDays.push('MON') }
            if (this.TuesdayChecked) { weekDays.push('TUE') }
            if (this.WednesdayChecked) { weekDays.push('WED') }
            if (this.ThursdayChecked) { weekDays.push('THU') }
            if (this.FridayChecked) { weekDays.push('FRI') }
            if (this.SaturdayChecked) { weekDays.push('SAT') }
            if (this.SundayChecked) { weekDays.push('SUN') }
        }
        this.model.Days = weekDays
        
        this.model.timeType == 'Advanced' ? this.model.translatedCornEx = this.croned : this.model.translatedCornEx = TranslateTheExpression(this.model);
  
        this.model.timeType == 'Advanced' ? this.model.cronEx : this.model.cronEx = this.FormatCronExpression(this.model);

        // reset not filled fields 
        this.ResetFields();
        
        try {
            await this.namespace.AddUpdateScheduleJob(this.model, this.model.workflows, true);
            this.relocate();

        } catch (error) {
            this.toastrObj.error(GetErrorMessage(error));

        }

        this.loading = false;
 

    }

    ResetFields() {
        if (this.model.timeType == 'Minutes') {

            this.model.everyHoursCount = undefined;
            this.model.hourlyAtMinute = undefined;
            this.model.dailyAtHour = undefined;
            this.model.dailyAtMinute = undefined;
            this.model.Days = undefined;
            this.model.weeklyAtHour = undefined;
            this.model.weeklyAtMinute = undefined;
            this.model.atDay = undefined;
            this.model.monthlyAtHour = undefined;
            this.model.monthlyAtMinute = undefined;
        }
        else if (this.model.timeType == 'Hourly') {
            this.model.everyMinutesCount = undefined;
            
            this.model.dailyAtHour = undefined;
            this.model.dailyAtMinute = undefined;
            this.model.weeklyAtHour = undefined;
            this.model.Days = undefined;
            this.model.weeklyAtMinute = undefined;
            this.model.atDay = undefined;
            this.model.monthlyAtHour = undefined;
            this.model.monthlyAtMinute = undefined;
        }
        else if (this.model.timeType == 'Daily') {
            this.model.everyMinutesCount = undefined;
            this.model.everyHoursCount = undefined;
            this.model.hourlyAtMinute = undefined;
            
            this.model.weeklyAtHour = undefined;
            this.model.Days = undefined;
            this.model.weeklyAtMinute = undefined;
            this.model.atDay = undefined;
            this.model.monthlyAtHour = undefined;
            this.model.monthlyAtMinute = undefined;
        }
        else if (this.model.timeType == 'Weekly') {
            this.model.everyMinutesCount = undefined;
            this.model.everyHoursCount = undefined;
            this.model.hourlyAtMinute = undefined;
            this.model.dailyAtMinute = undefined;

            this.model.atDay = undefined;
            this.model.monthlyAtHour = undefined;
            this.model.monthlyAtMinute = undefined;

        }
        else if (this.model.timeType == 'Monthly') {
            this.model.everyMinutesCount = undefined;
            this.model.everyHoursCount = undefined;
            this.model.hourlyAtMinute = undefined;
            this.model.dailyAtHour = undefined;
            this.model.dailyAtMinute = undefined;
            this.model.Days = undefined;
            this.model.weeklyAtHour = undefined;
            this.model.weeklyAtMinute = undefined;

        }
    }

    // to save schedule for the first time by first looping on the workflows that user chose
    // then loop on addschedulejob to add each workflow as a separated scheduled job 
    //then relocate user to scheule page

    isFormValid(){
        if(this.model.workflows.length === 0 || this.model.userMachines.length === 0 || this.loading || (this.model.timeType == 'Weekly' && !this.MondayChecked && !this.TuesdayChecked && !this.WednesdayChecked &&!this.ThursdayChecked&& !this.FridayChecked&&!this.SundayChecked && !this.SaturdayChecked)){
            return false;
        }else{
            return true;
        }
       
    }
    async SaveSchedule() {
        this.loading = true;
        this.model.status = 'Active';

        // save dayes as array of string from the check boxes 
        let weekDays: string[] = [];
        if (this.model.timeType == 'Weekly') {
            if (this.MondayChecked) { weekDays.push('MON') }
            if (this.TuesdayChecked) { weekDays.push('TUE') }
            if (this.WednesdayChecked) { weekDays.push('WED') }
            if (this.ThursdayChecked) { weekDays.push('THU') }
            if (this.FridayChecked) { weekDays.push('FRI') }
            if (this.SaturdayChecked) { weekDays.push('SAT') }
            if (this.SundayChecked) { weekDays.push('SUN') }
        }
        this.model.Days = weekDays

        this.model.timeType == 'Advanced' ? this.model.translatedCornEx = this.croned : this.model.translatedCornEx = TranslateTheExpression(this.model);
        
        this.model.timeType == 'Advanced' ? this.model.cronEx : this.model.cronEx = this.FormatCronExpression(this.model);


        try {
            await this.namespace.AddUpdateScheduleJob(this.model, this.model.workflows, true);
            this.relocate();
           
        } catch (error) {
            this.toastrObj.error(GetErrorMessage(error));
            
        }

        this.loading = false;

       

    }


    //take user to jobschedule page
    relocate() {
        this.$window.location.assign("/scheduledjobs");
        // this.$location.path("/");
    }
    //to prepare cron generator but it's not used now 
    // prepareCronFrom(){


    //     this.pluginName = "Angular-Cron-Generator";
    //     this.cronConfig = {
    //       allowMultiple: true,
    //       quartz: true
    //     };

    //     this.cronConfig1 = {};
    // }
    FormatCronExpression(model) {
        let expression = '';
        if (model.timeType == 'Minutes') {
            expression = '*/' + model.everyMinutesCount + ' * * * *';
    
        }
        else if (model.timeType == 'Hourly') {
            expression = model.hourlyAtMinute + ' */' + model.everyHoursCount + ' * * *';
    
        }
        else if (model.timeType == 'Daily') {
            expression = model.dailyAtMinute + ' ' + model.dailyAtHour + ' * * *';
    
        }
        else if (model.timeType == 'Weekly') {
            let weekDays: string[] = [];
            if (this.MondayChecked) { weekDays.push('MON') }
            if (this.TuesdayChecked) { weekDays.push('TUE') }
            if (this.WednesdayChecked) { weekDays.push('WED') }
            if (this.ThursdayChecked) { weekDays.push('THU') }
            if (this.FridayChecked) { weekDays.push('FRI') }
            if (this.SaturdayChecked) { weekDays.push('SAT') }
            if (this.SundayChecked) { weekDays.push('SUN') }
    
            weekDays.length > 0 ? expression = model.weeklyAtMinute + ' ' + model.weeklyAtHour + ' * * ' + (weekDays.length > 1 ? weekDays.join(',') : weekDays[0]) : this.toastrObj.error(GetErrorMessage('select the day'));
        }
        else if (model.timeType == 'Monthly') {
            expression = model.monthlyAtMinute + ' ' + model.monthlyAtHour + ' ' + model.atDay + ' * *';
    
        }
        var index = expression.indexOf("undefined"); 
        if(index!=-1){
            expression = ''
        }
        return expression;
    
    }

}

// enhance the Cron Expression to be inline with the written sentense 
function TranslateTheExpression(model) {
    let traslatedExp = ''
    if (model.timeType == 'Minutes') {

        traslatedExp = 'Every ' + model.everyMinutesCount + ' Minutes';
    }
    else if (model.timeType == 'Hourly') {
        traslatedExp = 'Every ' + model.everyHoursCount + ' hour(s) ' + 'at minute(s) ' + model.hourlyAtMinute
    }
    else if (model.timeType == 'Daily') {
        traslatedExp = 'Every day At  (' + (model.dailyAtHour > 12 ? model.dailyAtHour - 12 : model.dailyAtHour) + ' : ' + model.dailyAtMinute + (model.dailyAtHour >= 12 ? ' PM)' : ' AM)')
    }
    else if (model.timeType == 'Weekly') {
        let weekDays = model.Days;
        traslatedExp = 'Every Week on  ' + (weekDays.length > 1 ? weekDays.join(' , ') : weekDays[0]) + ' At ('
            + (model.weeklyAtHour > 12 ? model.weeklyAtHour - 12 : model.weeklyAtHour) + ' : ' + model.weeklyAtMinute + (model.weeklyAtHour >= 12 ? ' PM)' : ' AM)')
    }
    else if (model.timeType == 'Monthly') {
        traslatedExp = 'Every month On day  ' + model.atDay + ' At (' + (model.monthlyAtHour > 12 ? model.monthlyAtHour - 12 : model.monthlyAtHour) + ' : ' + model.monthlyAtMinute + (model.monthlyAtHour >= 12 ? ' PM)' : ' AM)')
    }
    var index = traslatedExp.indexOf("undefined"); 
    if(index!=-1){
        traslatedExp = ''
    }
    return traslatedExp
}

function FormatCronExpression2(expression) {

    var arr = expression.split(' ');
    const arrayLength = arr.length;
    var lastPartHasNum = !isNaN(arr[arrayLength - 1]) || !isNaN(arr[arrayLength - 2]) || !isNaN(arr[arrayLength - 3]);

    if (arr.length >= 4 && !lastPartHasNum) {
        for (let i = 0; i < arr.length - 3; i++) {
            if (!isNaN(arr[i]) && arr[0] === '*' && i !== 0) {
                arr[0] = '0';
            }
            if (!isNaN(arr[i]) && arr[i] !== '0') {
                if (i !== 0 && arr[0].indexOf('0/') >= 0) {
                    arr[0] = arr[0].replace('0/', '')
                }

                arr[i] = '0/' + arr[i];

            }
        }

        expression = arr.join(' ')
    }

    return expression;
}
